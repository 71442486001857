/* eslint-disable max-lines */
import { getBrowserWindow, AJAX, ADRUMError, VPageView } from './window.js';
import {
  BRUMConfig,
  FilterPattern,
  UserEventInfoFn,
  XHR,
  SPA,
  SPA2,
  UserEventInfo,
} from './brumConfig.js';

const browserWindow = getBrowserWindow();

// check for adrum script injection
const isScriptLoaded = () => browserWindow.ADRUM;

// To get error event handle to set required error log properties
export const getErrorHandle = (): ADRUMError => {
  const adrum = browserWindow.ADRUM;
  if (!adrum) throw new Error("'adrum' is not initialized");
  return new adrum.events.Error();
};

// To get ajax event handle to set required ajax log properties
export const getAjaxHandle = (): AJAX => {
  const adrum = browserWindow.ADRUM;
  if (!adrum) throw new Error("'adrum' is not initialized");
  return new adrum.events.Ajax();
};

// To get virtual page view event handle to set required ajax log properties
export const getVPageViewHandle = (): VPageView => {
  const adrum = browserWindow.ADRUM;
  if (!adrum) throw new Error("'adrum' is not initialized");
  return new adrum.events.VPageView();
};

// To Add Custom User Data to a Page Browser Snapshot
// Ref => https://docs.appdynamics.com/display/PRO45/Add+Custom+User+Data+to+a+Page+Browser+Snapshot
export const setCustomUserData = (onUserEvent: UserEventInfoFn) => {
  if (typeof onUserEvent !== 'function')
    throw new Error('Please provide custom user data callback function');
  const config = window['adrum-config'] || {};
  const userEventInfo = {
    PageView: onUserEvent.bind(null, 'pageview'),
    VPageView: onUserEvent.bind(null, 'vpageview'),
    Ajax: onUserEvent.bind(null, 'ajax'),
  };
  config.userEventInfo = userEventInfo;
  window['adrum-config'] = config;
};

// To Filter XHR Calls by URLs
// https://docs.appdynamics.com/display/PRO45/Filter+XHR+Calls+by+URLs
// Note : To use XHR filters, you must assign XHR filters to xhr.include and xhr.exclude before you inject the adrum.js script
/*
The general structure for the filter object
params => filterPattern
For ex.
{
  include : {
      urls:
        [
          {
            pattern: ".*foo.*"
          },
          {
            pattern: ".*bar.*"
          }
        ],
      method: 'GET'
  },
  exclude : {
      urls:
        [
          {
            pattern: ".*user-profile.*"
          }
        ],
      method: 'POST'
  }
}
*/
export const setXHRFilter = (filterPattern: FilterPattern) => {
  if (!isScriptLoaded()) {
    throw new Error(
      'To use XHR filters, you must assign XHR filters to xhr.include and xhr.exclude before you inject the adrum.js script',
    );
  }
  if (filterPattern && (filterPattern.include || filterPattern.exclude)) {
    const config = window['adrum-config'] || {};
    const xhr = config.xhr || {};
    if (filterPattern.include) {
      xhr.include = filterPattern.include;
    }
    if (filterPattern.exclude) {
      xhr.exclude = filterPattern.exclude;
    }
    config.xhr = xhr;
    window['adrum-config'] = config;
  } else {
    throw new Error('XHR Include\\Exclude filter patterns are not valid');
  }
};

// To Report Events with the JavaScript API
// Ref => https://docs.appdynamics.com/display/PRO45/Report+Events+with+the+JavaScript+API
// To report exception/error
// Param => exception
// e.g {type : 'Uncaught Exception', }
export const reportError = (exception: { line?: number; message?: string }) => {
  const adrum = browserWindow.ADRUM;
  if (!adrum) throw new Error('ADRUM is not initialized');
  const err = getErrorHandle();
  err.line(exception?.line || 0);
  err.msg(exception?.message || '');
  adrum.report(err);
};

// To Report a custom Ajax event passing properties
// Ref => https://docs.appdynamics.com/display/PRO45/Report+Events+with+the+JavaScript+API
// eslint-disable-next-line complexity
export const reportAjax = (reportInfo: {
  url?: string;
  markSendTime?: number;
  markFirstByteTime?: number;
  markRespAvailTime?: number;
  markRespProcTime?: number;
}) => {
  const adrum = browserWindow.ADRUM;
  if (!adrum) throw new Error('ADRUM is not initialized');
  const ajax = getAjaxHandle();
  // set url
  ajax.url(reportInfo?.url || 'NA');
  // mark timings
  ajax.markSendTime(reportInfo?.markSendTime || 0);
  ajax.markFirstByteTime(reportInfo?.markFirstByteTime || 0);
  ajax.markRespAvailTime(reportInfo?.markRespAvailTime || 0);
  ajax.markRespProcTime(reportInfo?.markRespProcTime || 0);
  adrum.report(ajax);
};

// To Report a custom Ajax/Error/VPageView event passing properties
// Make use of handlers as per need
// Ref => https://docs.appdynamics.com/display/PRO45/Report+Events+with+the+JavaScript+API
export const report = (event: any) => {
  const adrum = browserWindow.ADRUM;
  if (!adrum) throw new Error("'adrum' is not initialized");
  adrum.report(event);
};

// To Disable Monitoring of Fetch API Calls
// Ref => https://docs.appdynamics.com/display/PRO45/Disable+Monitoring+of+Fetch+API+Calls
export const disableFetchMonitoring = (isSPA: boolean, isAngular: boolean) => {
  const config: BRUMConfig = window['adrum-config'] || {};
  config.fetch = false;
  if (isSPA) {
    config.spa = {
      spa2: true,
    };
  }
  if (isAngular) {
    config.angular = true;
  }
  window['adrum-config'] = config;
};

// To Handle the window.onerror Event
// Ref => https://docs.appdynamics.com/display/PRO45/Handle+the+window.onerror+Event
// Note: If any script on your monitored web pages, including library code, sets the JavaScript window.onerror event,
// add the following method to the page immediately after setting window.onerror
export const reportWindowOnError = () => {
  const adrum = browserWindow.ADRUM;
  if (!adrum) throw new Error('AppDynamics is not initialized');
  adrum.listenForErrors();
};

// To Disable Browser Monitoring Programmatically
// Ref => https://docs.appdynamics.com/display/PRO45/Disable+Browser+Monitoring+Programmatically
// Note : To disable Browser Monitoring add the snippet below before adrum.js agent is injected.
export const disableBrowserMonitoring = () => {
  window['adrum-disable'] = true;
};

// To Enable Browser Monitoring Programmatically
export const enableBrowserMonitoring = () => {
  window['adrum-disable'] = false;
};

// To Set Custom Page Names
// Ref => https://docs.appdynamics.com/display/PRO45/Set+Custom+Page+Names
export const setCustomPageName = (userPageName: string) => {
  if (userPageName) {
    const config = window['adrum-config'] || ({} as BRUMConfig);
    const userEventInfo = config.userEventInfo || ({} as UserEventInfo);
    const pageView = userEventInfo.PageView || {};
    pageView.userPageName = userPageName;
    userEventInfo.PageView = pageView;
    config.userEventInfo = userEventInfo;
    window['adrum-config'] = config;
  }
};

// To Set Custom Virtual Page Names
// Ref => https://docs.appdynamics.com/display/PRO45/Set+Custom+Virtual+Page+Names
// Note : To name virtual pages with the JavaScript Agent, you need to enable SPA2 monitoring.
export const setCustomVirtualPageName = (myCustomVPName: string) => {
  const adrum = browserWindow.ADRUM;
  if (!adrum) throw new Error('AppDynamics is not initialized');
  adrum.command('setVirtualPageName', myCustomVPName);
};

// Marking the beginning of the virtual page and waiting for the end to be manually marked.
export const startVirtualPageMonitoring = (myCustomVPName: string) => {
  const adrum = browserWindow.ADRUM;
  if (!adrum) throw new Error('AppDynamics is not initialized');
  adrum.markVirtualPageBegin(myCustomVPName, true);
};

// Marking the beginning of the virtual page and allowing the JS Agent to mark the end of the virtual page.
export const startVirtualPageMonitoringWithAutoEnd = (
  myCustomVPName: string,
) => {
  const adrum = browserWindow.ADRUM;
  if (!adrum) throw new Error('AppDynamics is not initialized');
  adrum.markVirtualPageBegin(myCustomVPName, false);
};

// Manually marking the end of the virtual page.
export const endVirtualPageMonitoring = () => {
  const adrum = browserWindow.ADRUM;
  if (!adrum) throw new Error('AppDynamics is not initialized');
  adrum.markVirtualPageEnd();
};

export const setXHRPayloadParams = (payloadParams: string) => {
  if (payloadParams) {
    const config = window['adrum-config'] || {};
    const xhr = config.xhr || {};
    xhr.payloadParams = payloadParams;
    config.xhr = xhr;
    window['adrum-config'] = config;
  } else {
    throw new Error('Please provide valid XHR payload params');
  }
};

// To Set Ajax Request Names Based on Captured POST Parameters
// Ref => https://docs.appdynamics.com/display/PRO45/Set+Ajax+Request+Names+Based+on+Captured+POST+Parameters
export const captureApiPostParameters = (
  urls: URL[],
  getFromBodyCB: (data: string) => void,
) => {
  if (urls) {
    const config = window['adrum-config'] || {};
    const xhr = config.xhr || ({} as XHR);
    xhr.parameter = {
      urls,
    };
    if (typeof getFromBodyCB === 'function') {
      xhr.parameter.getFromBody = getFromBodyCB;
    }
    config.xhr = xhr;
    window['adrum-config'] = config;
  } else {
    throw new Error('Please provide valid url pattern');
  }
};

// To Set the Maximum Number of Segments
// Ref => https://docs.appdynamics.com/display/PRO45/Configure+the+Number+and+Length+of+URL+Segments
export const setMaxResUrlSegmentNumber = (segmentNumber: number) => {
  if (typeof segmentNumber === 'number') {
    const config = window['adrum-config'] || {};
    config.maxResUrlSegmentNumber = segmentNumber;
    window['adrum-config'] = config;
  } else {
    throw new Error('Please provide valid Segment Number');
  }
};

export const setSPA2 = () => {
  const config = window['adrum-config'] || {};
  const spa = config.spa || ({} as SPA);
  spa.spa2 = true;
  config.spa = spa;
  window['adrum-config'] = config;
};

// To Hide All or Parts of the URL Query String
// Ref => https://docs.appdynamics.com/display/PRO45/Hide+All+or+Parts+of+the+URL+Query+String
// filterURLQuery can be false(by default), true or array of keys
export const filterURLQueryString = (filterURLQuery: string) => {
  if (filterURLQuery) {
    const config = window['adrum-config'] || {};
    const urlCapture = config.urlCapture || {};
    urlCapture.filterURLQuery = filterURLQuery;
    config.urlCapture = urlCapture;
    window['adrum-config'] = config;
  } else {
    throw new Error(
      'Please provide valid filter for query string; i.e true, false, or array of keys',
    );
  }
};

// To Exclude Virtual Pages from Being Monitored
// Ref => https://docs.appdynamics.com/display/PRO45/Exclude+Virtual+Pages+from+Being+Monitored
// Param =>
// {
//  exclude: {
//   "urls": [
//            {"pattern": "contact"},
//            {"pattern": "api*"}
//            ]
//  }
// }
export const setVPageFilter = (filterPattern: FilterPattern) => {
  if (filterPattern && (filterPattern.include || filterPattern.exclude)) {
    const config: BRUMConfig = window['adrum-config'] || {};
    const spa: SPA = config.spa || ({} as SPA);
    const spa2: SPA2 = spa.spa2 || ({} as SPA2);
    const vp: FilterPattern = spa2.vp || {};
    if (filterPattern.include) {
      vp.include = filterPattern.include;
    }
    if (filterPattern.exclude) {
      vp.exclude = filterPattern.exclude;
    }
    spa2.vp = vp;
    spa.spa2 = spa2;
    config.spa = spa;
    window['adrum-config'] = config;
  } else {
    throw new Error(
      'Virtual Page Include\\Exclude filter patterns are not valid',
    );
  }
};

// Exclude Ajax from VPageView using ADRUM configuration
/*
The general structure for the filter object
params => filterPattern
For ex.
{
  include : {
      urls:
        [
          {
            pattern: ".*foo.*"
          },
          {
            pattern: ".*bar.*"
          }
        ],
      method: 'GET'
  },
  exclude : {
      urls:
        [
          {
            pattern: ".*user-profile.*"
          }
        ],
      method: 'POST'
  }
}
*/
export const setVPFilter = (filterPattern: FilterPattern) => {
  if (!isScriptLoaded()) {
    throw new Error(
      'To use XHR filters for virtual page, you must assign XHR filters to xhr.include and xhr.exclude before you inject the adrum.js script',
    );
  }
  if (filterPattern && (filterPattern.include || filterPattern.exclude)) {
    const config: BRUMConfig = window['adrum-config'] || {};
    const spa: SPA = config.spa || ({} as SPA);
    const angular = {
      vp: {
        xhr: filterPattern,
      },
    };
    spa.angular = angular;
    config.spa = spa;
    window['adrum-config'] = config;
  } else {
    throw new Error(
      'XHR Include\\Exclude filter patterns for virtual page are not valid',
    );
  }
};

// eslint-disable-next-line complexity
const getDefaultConfig = (adrumConfig: BRUMConfig) => {
  const config = adrumConfig;
  const scriptUrl =
    (document.currentScript as HTMLScriptElement)?.src || window.location.href;
  const defaultAdrumExtUrlHttp = new URL(
    scriptUrl.includes('/js') ? 'vendor' : 'js/vendor',
    scriptUrl,
  ).href;
  config.beaconUrlHttp =
    config.beaconUrlHttp || 'http://pdx-col.eum-appdynamics.com';
  config.beaconUrlHttps =
    config.beaconUrlHttps || 'https://pdx-col.eum-appdynamics.com';

  config.adrumExtUrlHttp = config.adrumExtUrlHttp || defaultAdrumExtUrlHttp;
  config.adrumExtUrlHttps = config.adrumExtUrlHttps || defaultAdrumExtUrlHttp;

  config.useHTTPSAlways = config.useHTTPSAlways || true;
  config.xd = config.xd || { enable: true };
  config.spa = config.spa || { spa2: true };
  config.enableCoreWebVitals = config.enableCoreWebVitals || true;
  if (config.customData) {
    const customDataFn: UserEventInfoFn = config.customData;
    config.userEventInfo = {
      PageView: customDataFn.bind(null, 'pageview'),
      VPageView: customDataFn.bind(null, 'vpageview'),
      Ajax: customDataFn.bind(null, 'ajax'),
    };
  }
  return config;
};

const validateConfig = (config: BRUMConfig) => {
  if (!config || !config?.appKey) {
    // eslint-disable-next-line no-console
    console.error(
      'window.emui.brumConfig.appKey is not set to a valid AppDynamics key',
    );
    return false;
  }
  return true;
};

const init = (config: BRUMConfig) => {
  if (!validateConfig(config)) return;
  const adrumConfig = window['adrum-config'] || {};
  window['adrum-config'] = Object.assign(adrumConfig, getDefaultConfig(config));
  window['adrum-app-key'] = config.appKey || '';
  window['adrum-start-time'] = new Date().getTime();
  // load app dynamics script
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  import('./adrum.exec')
    .then(() => {
      if (window.emui?.logger) {
        window.onerror = null; // pui-diagnostics library takes care of logging errors
        window.emuiDiagnostics?.logUnhandledErrors?.(window.emui.logger);
      }
    })
    .catch((err) => console.error(err));
};

init(window.emui?.brumConfig);
