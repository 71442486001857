import { getCLS, getFID, getLCP, ReportHandler } from 'web-vitals';

const reportWebVitals: ReportHandler = (metric) => {
  window.gtmDataLayer = window.gtmDataLayer || [];
  window.gtmDataLayer.push({
    event: 'webVitals',
    ...metric,
  });
};

getCLS(reportWebVitals);
getFID(reportWebVitals);
getLCP(reportWebVitals);
